<template>
  <secured-area
    :operations="['List_Users', 'View_User', 'Edit_User', 'Add_User_To_Role', 'Remove_User_From_Role', 'View_Users_Rights']"
    hideWhenAccessIsRejected
  >
  <div>
    <fieldset class="fieldset" v-show="filtersEnabled">
      <legend>Filtre(s)</legend>
    </fieldset>
    <b-table small hover :items="itemsList" :fields="columns" class="thisTable" responsive>

        <template #cell(selector)="data">
            <b-button size="sm" :disabled="data.item.cannotBeSelected" @click="select(data.item)">Select.</b-button>
        </template>

        <template #cell(username)="data">
          <span class="username">{{ data.item.username}}</span>
        </template>
        <template #cell(firstname)="data">
          <span class="firstname">{{ data.item.firstname}}</span>
        </template>
        <template #cell(lastname)="data">
          <span class="lastname">{{ data.item.lastname }}</span>
        </template>
        <template #cell(email)="data">
          <a v-show="data.item.email != ''" :href="'mailto:' + data.item.email">{{ data.item.email }}</a>
        </template>
        <template #cell(nickname)="data">
          <span class="nickname">{{ data.item.nickname}}</span>
        </template>
        <template #cell(rolesCount)="data">
          <span class="rolesCount" :title="data.item.activeRoles">{{ data.item.activeRolesCount }}</span>
        </template>
        <template #cell(active)="data">
          <b-checkbox v-model="data.item.active" :disabled="true" />
        </template>
        <template #cell(accountExpiration)="data">
          <span v-show="data.item.accountNeverExpire" class="account-never-expire">Jamais</span>
          <span v-show="!data.item.accountNeverExpire" class="account" :class="{ 'account-has-expired': data.item.accountHasExpired, 'account-will-expire-soon': data.item.accountWillExpireWithin30DDays}">{{ data.item.accouuntExpirationDate }}</span>
        </template>

        <template #cell(commands)="data">
            <row-commands
              :rowItem = "data.item.numero"

              :viewBtnVisible="canView || CanViewUsersInRole || CanViewUserRights"
              @viewBtnClick="view"

              :editBtnVisible="!viewOnlyMode && (canEdit || canAddUserToRole || CanRemoveUserFromRole)"
              @editBtnClick="edit"

              :archiveBtnVisible="!viewOnlyMode && canArchive && data.item.isActive"
              @archiveBtnClick="archive"

              :unarchiveBtnVisible="!viewOnlyMode && canUnarchive && !data.item.isActive"
              @unarchiiveBtnClick="unarchive"

              :deleteBtnVisible="!viewOnlyMode && canRemove && !data.item.isActive"
              :deleteBtnDisabled="data.item.rolesCount > 0"
              @deleteBtnClick="remove"
            />
        </template>
    </b-table>

    <b-modal
      v-model="showDeletionConfirmationDialog"
      title="Confirmation de suppression"
      @ok="remove(userToDelete)"
      @cancel="userToDelete=null"
      ok-variant="warning"
      ok-title="Oui, supprimer"
      cancel-variant="secondary"
      cancel-title="Non"
    >
      <p>Etes-vous sûr de vouloir supprimer cet utilisateur ?</p>
    </b-modal>
  </div>
  </secured-area>
</template>

<script>
import RowCommands from "@/components/Lists/RowCommands.vue";
import SecuredArea from "@/components/Security/SecuredArea.vue";
import { mapActions } from "vuex";

export default {
  components: { RowCommands, SecuredArea },
  props: {
    viewOnlyMode: { type: Boolean, default: false },
    items: { type: Array },
    usernameColumnVisible: { type: Boolean, default: true },
    firstnameColumnVisible: { type: Boolean, default: true },
    lastnameColumnVisible: { type: Boolean, default: true },
    rolesCountColumnVisible: { type: Boolean, default: true },
    emailColumnVisible: { type: Boolean, default: true },
    nicknameColumnVisible: { type: Boolean, default: true },
    isActiveColumnVisible: { type: Boolean, default: true },
    accountExpirationColumnVisible: { type: Boolean, default: true },
    filtersEnabled: { type: Boolean, default: false },
  },
  data(){
    return {
      showDeletionConfirmationDialog: false,
      userToDelete: null,
      canView: false,
      canEdit: false,
      canArchive: false,
      canUnarchive: false,
      canAddUserToRole: false,
      CanRemoveUserFromRole: false,
      CanViewUsersInRole: false,
      CanViewUserRights: false,
      canRemove: false,
    }
  },
  computed:{
    columns(){
      var ret = [];

      if(this.usernameColumnVisible)
        //ret.push({key: 'selector', sortable: false, label: '', class:"selector", stickyColumn: true});
        ret.push({key: 'username', sortable: true, label: 'Login', stickyColumn: true});

      if(this.firstnameColumnVisible)
        ret.push({ key: 'firstname', sortable: true, label: 'Prénom'});

      if(this.lastnameColumnVisible)
        ret.push({ key: 'lastname', sortable: false, label: 'Nom'});

      if(this.emailColumnVisible)
        ret.push({ key: 'email', sortable: true, label: 'Courriel'});

      if(this.nicknameColumnVisible)
        ret.push({ key: 'nickname', sortable: true, label: 'Surnom'});

      if(this.rolesCountColumnVisible)
        ret.push({ key: 'rolesCount', sortable: true, label: 'Roles'});

      if(this.isActiveColumnVisible)
        ret.push({ key: 'isActive', sortable: true, label:'Actif'});

      if(this.accountExpirationColumnVisible)
        ret.push({ key:'accountExpiration', sortable: true, label: 'Expiration'});


      ret.push({ key: 'commands', sortable: false, label: ''});

      return ret;
    },
  },
  methods: {
    ...mapActions('auth', ['userCan']),
    view(item){
      this.$emit('view', item);
    },
    edit(item){
      console.log('emit edit', item);
      this.$emit('edit', item);
    },
    archive(item){
      this.$emit('archive', item);
    },
    unarchive(item){
      this.$emit('unarchive', item);
    },
    confirmDeletion(item){
      this.userToDelete = item;
      this.showDeletionConfirmationDialog = true;
    },
    remove(item){
      this.$emit('remove', item);
    },
    async itemsList(){
      this.canView = await this.userCan('View_User');
      this.canEdit = await this.userCan('Edit_User');
      this.canArchive = await this.userCan('Archive_User');
      this.canUnarchive = await this.userCan('Unarchive_User');
      this.canAddUserToRole = await this.userCan('Add_User_To_Role');
      this.CanRemoveUserFromRole = await this.userCan('Remove_User_From_Role');
      this.CanViewUsersInRole = await this.userCan('View_Users_In_Roles');
      this.canRemove = await this.userCan('Remove_User');
      this.CanViewUserRights = await this.userCan('View_User_Rights');
      var ret = [];

      if(this.items != null){
        ret = this.items.map(element => {
          return {
            numero: element.id,
            username: element.username,
            firstname: element.firstname,
            lastname: element.lastname,
            email: element.email,
            nickname: element.nickname,
            accountNeverExpire: element.accountNeverExpire,
            accountExpirationDate: element.accountExpirationDate,
            isActive: (element.isActive == 1),
            isDeletable: !element.isInRole,
            rolesCount: element.totalRolesCount,
            activeRolesCount: element.activeRoles.length,
            activeRoles: element.activeRoles.join(', '),
            cannotBeDeleted: true,
          }
        });
      }

      return ret;
    }
  }
}
</script>
