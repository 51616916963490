<template>
  <secured-area :operations="['List_Users', 'View_User', 'Create_User', 'Edit_User', 'Remove_User', 'Add_User_To_Role', 'Remove_User_From_Role', 'View_Users_In_Roles', 'View_Users_Rights']">
    <h1>Utilisateurs</h1>
    <users-list
      :items = "users"
      @view = "onViewItem"
      @edit = "onEditItem"
      @archive = "onArchiveItem"
      @unarchive = "onUnarchiveItem"
      @remove = "onRemoveItem"
    />
  </secured-area>
</template>

<script>
import SecuredArea from '@/components/Security/SecuredArea.vue';
import UsersList  from '@/components/Security/Users/List.vue';
import axios from 'axios';

export default {
  components: { SecuredArea, UsersList },
  data(){
    return {
      users: [],
    }
  },
  methods:{
    onViewItem(item){
      console.log('onViewItem', item);
    },
    onEditItem(item){
      console.log('onEditItem', item);
      this.$showLoader()
        .then(() => {
          this.$router.push({ name: 'SecurityUserEdit', params: { id: item}});
        });
    },
    onArchiveItem(item){
      console.log('onArchiveItem', item);
    },
    onUnarchiveItem(item){
      console.log('onUnarchiveItem', item);
    },
    onRemoveItem(item){
      console.log('onDeleteItem', item);
    },
    async refresh(){
      await this.$showRefresher();
      var url = this.getUrl('/api/Security/getUsers.php');

      axios.post(url)
        .then((response) => {
          this.users = response.data.users;
          this.$hideRefresher();
        })
        .catch(error => {
          console.log(error);
          this.$toastError("Impossible d'accéder au serveur pour récupérer les données !")
          this.$hideRefresher();
        })
    }
  },
  async mounted(){
    await this.refresh();
    this.$hideLoader();
  }
}
</script>
